import { useEffect } from 'react';
import { useServiceWorker } from '../hooks/useServiceWorker';
import { toast } from 'react-hot-toast';

export function ServiceWorkerUpdate() {
    const { needsRefresh, updateServiceWorker } = useServiceWorker();

    useEffect(() => {
        if (needsRefresh) {
            toast((t) => (
                <div className="flex items-center gap-4">
                    <p className="text-sm">Une mise à jour est disponible</p>
                    <div className="flex gap-2">
                        <button
                            onClick={() => {
                                toast.dismiss(t.id);
                                updateServiceWorker();
                            }}
                            className="px-3 py-1 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            Mettre à jour
                        </button>
                        <button
                            onClick={() => toast.dismiss(t.id)}
                            className="px-3 py-1 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        >
                            Plus tard
                        </button>
                    </div>
                </div>
            ), {
                duration: Infinity,
                position: 'bottom-center',
            });
        }
    }, [needsRefresh, updateServiceWorker]);

    return null;
}
