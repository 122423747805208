type MetricType = 'performance' | 'error' | 'user' | 'network';

interface MetricPayload {
    name: string;
    value: number | string;
    type: MetricType;
    timestamp: number;
    context?: Record<string, any>;
}

interface ErrorPayload {
    error: Error;
    context?: Record<string, any>;
    timestamp: number;
    componentStack?: string;
}

class TelemetryService {
    private static instance: TelemetryService;
    private metricsQueue: MetricPayload[] = [];
    private errorsQueue: ErrorPayload[] = [];
    private readonly flushInterval = 10000; // 10 secondes
    private readonly maxQueueSize = 100;

    private constructor() {
        this.startPeriodicFlush();
        this.initPerformanceObserver();
    }

    public static getInstance(): TelemetryService {
        if (!TelemetryService.instance) {
            TelemetryService.instance = new TelemetryService();
        }
        return TelemetryService.instance;
    }

    private startPeriodicFlush(): void {
        setInterval(() => {
            this.flush();
        }, this.flushInterval);
    }

    private initPerformanceObserver(): void {
        if (typeof window === 'undefined') return;

        // Observer pour les métriques Web Vitals
        const observer = new PerformanceObserver((list) => {
            for (const entry of list.getEntries()) {
                this.trackPerformance(entry.name, entry.startTime);
            }
        });

        // Observer différents types de métriques de performance
        observer.observe({ 
            entryTypes: ['largest-contentful-paint', 'first-input', 'layout-shift'] 
        });
    }

    public trackError(error: Error, context?: Record<string, any>): void {
        const errorPayload: ErrorPayload = {
            error,
            context,
            timestamp: Date.now(),
            componentStack: error.stack
        };

        this.errorsQueue.push(errorPayload);
        
        if (this.errorsQueue.length >= this.maxQueueSize) {
            this.flush();
        }

        // Log en développement
        if (process.env.NODE_ENV === 'development') {
            console.error('Telemetry Error:', error, context);
        }
    }

    public trackPerformance(metric: string, value: number, context?: Record<string, any>): void {
        this.addMetric({
            name: metric,
            value,
            type: 'performance',
            timestamp: Date.now(),
            context
        });
    }

    public trackUserAction(action: string, context?: Record<string, any>): void {
        this.addMetric({
            name: action,
            value: 1,
            type: 'user',
            timestamp: Date.now(),
            context
        });
    }

    public trackNetworkRequest(url: string, duration: number, success: boolean): void {
        this.addMetric({
            name: 'network_request',
            value: duration,
            type: 'network',
            timestamp: Date.now(),
            context: { url, success }
        });
    }

    private addMetric(metric: MetricPayload): void {
        this.metricsQueue.push(metric);

        if (this.metricsQueue.length >= this.maxQueueSize) {
            this.flush();
        }
    }

    private async flush(): Promise<void> {
        if (this.metricsQueue.length === 0 && this.errorsQueue.length === 0) return;

        const metrics = [...this.metricsQueue];
        const errors = [...this.errorsQueue];
        this.metricsQueue = [];
        this.errorsQueue = [];

        try {
            // Envoyer les données à votre service d'analytics
            // Exemple avec Vercel Analytics
            if (typeof window !== 'undefined' && window.va) {
                metrics.forEach(metric => {
                    // Appeler directement la fonction va avec le type 'event'
                    window.va?.('event', {
                        name: metric.name,
                        value: metric.value,
                        type: metric.type,
                        ...metric.context
                    });
                });
            }

            // Vous pouvez aussi envoyer à votre propre endpoint
            /*
            await fetch('/api/telemetry', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ metrics, errors })
            });
            */
        } catch (error) {
            console.error('Failed to send telemetry:', error);
            // Remettre les métriques dans la queue en cas d'échec
            this.metricsQueue = [...this.metricsQueue, ...metrics];
            this.errorsQueue = [...this.errorsQueue, ...errors];
        }
    }
}

// Export l'instance singleton
export const telemetry = TelemetryService.getInstance();

// Types pour window
declare global {
    interface Window {
        va?: (event: "beforeSend" | "event" | "pageview", properties?: unknown) => void;
    }
}
