import { useEffect, useState } from 'react';
import { Workbox, messageSW } from 'workbox-window';
import { telemetry } from '../services/telemetry';

interface ServiceWorkerState {
    isRegistered: boolean;
    needsRefresh: boolean;
    registration: ServiceWorkerRegistration | null;
    error: Error | null;
}

export function useServiceWorker() {
    const [state, setState] = useState<ServiceWorkerState>({
        isRegistered: false,
        needsRefresh: false,
        registration: null,
        error: null
    });

    useEffect(() => {
        if (
            process.env.NODE_ENV === 'production' &&
            'serviceWorker' in navigator &&
            window.workbox !== undefined
        ) {
            const wb = new Workbox('/service-worker.js', {
                scope: '/'
            });

            const handleRegistration = async () => {
                try {
                    const registration = await wb.register();
                    setState(prev => ({
                        ...prev,
                        isRegistered: true,
                        registration
                    }));

                    telemetry.trackPerformance('sw_registered', 1);
                } catch (error) {
                    setState(prev => ({
                        ...prev,
                        error: error as Error
                    }));
                    telemetry.trackError(error as Error, { context: 'sw_registration' });
                }
            };

            // Écouter les mises à jour
            wb.addEventListener('waiting', event => {
                setState(prev => ({
                    ...prev,
                    needsRefresh: true
                }));
                telemetry.trackPerformance('sw_update_available', 1);
            });

            // Écouter les erreurs
            wb.addEventListener('activated', event => {
                telemetry.trackPerformance('sw_activated', 1);
            });

            wb.addEventListener('controlling', event => {
                window.location.reload();
            });

            wb.addEventListener('message', event => {
                console.log('Message from service worker:', event);
            });

            // Enregistrer le service worker
            handleRegistration();

            return () => {
                // Cleanup si nécessaire
            };
        }
    }, []);

    const updateServiceWorker = async () => {
        if (state.registration && state.registration.waiting) {
            try {
                await messageSW(state.registration.waiting, { type: 'SKIP_WAITING' });
                setState(prev => ({
                    ...prev,
                    needsRefresh: false
                }));
                telemetry.trackPerformance('sw_updated', 1);
            } catch (error) {
                telemetry.trackError(error as Error, { context: 'sw_update' });
            }
        }
    };

    return {
        ...state,
        updateServiceWorker
    };
}

// Types pour window
declare global {
    interface Window {
        workbox: any;
    }
}
