import { Suspense, lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthProvider';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { ErrorBoundary } from './components/ErrorBoundary';
import { LoadingSpinner } from './components/LoadingSpinner';
import { Toaster } from 'react-hot-toast';
import { ServiceWorkerUpdate } from './components/ServiceWorkerUpdate';
import { telemetry } from './services/telemetry';

// Lazy loading des composants
const AppBanner = lazy(() => {
  telemetry.trackPerformance('load_app_banner', performance.now());
  return import('./components/AppBanner').then(module => ({ default: module.AppBanner }));
});

const AppContent = lazy(() => {
  telemetry.trackPerformance('load_app_content', performance.now());
  return import('./AppContent').then(module => ({ default: module.default }));
});

export default function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AuthProvider>
          <Suspense fallback={
            <LoadingSpinner 
              size="lg" 
              className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" 
            />
          }>
            <AppBanner />
            <AppContent />
            <Analytics />
            <SpeedInsights />
            <ServiceWorkerUpdate />
            <Toaster 
              position="bottom-center"
              toastOptions={{
                className: 'dark:bg-gray-800 dark:text-white',
                duration: 5000,
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }}
            />
          </Suspense>
        </AuthProvider>
      </Router>
    </ErrorBoundary>
  );
}